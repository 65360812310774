import React from "react";
import { Link } from "react-router-dom";

const SunGlassSection = () => {
  return (
    <section className="fz-5-product pt-120 pb-120">
      <div className="container">
        <div className="row g-xl-4 g-lg-3 g-2">
          <div className="col-lg-3 col-md-4 col-6 order-lg-1 order-2">
            <div className="fz-5-single-product">
              <div className="fz-5-single-product-img">
                <img src="assets/images/fz-product-35.png" alt="Image" />
                <div className="fz-5-single-product-actions">
                  <a role="button" className="fz-add-to-wishlist">
                    <i className="fa-regular fa-heart"></i>
                  </a>
                  <a role="button" className="fz-quick-view">
                    <i className="fa-regular fa-eye"></i>
                  </a>
                  <a role="button" className="fz-compare">
                    <i className="fa-regular fa-repeat"></i>
                  </a>
                  <a role="button" className="fz-add-to-cart-btn">
                    <i className="fa-regular fa-cart-shopping"></i>
                  </a>
                </div>
              </div>
              <div className="fz-5-single-product-txt">
                <div className="color-available">
                  <span className="color-1"></span>
                  <span className="color-2"></span>
                  <span className="color-3"></span>
                  <span className="color-4"></span>
                  <span className="color-5"></span>
                </div>
                <h3 className="fz-5-single-product-title">
                  <Link to="/shopDetails">Lenskart Blu Hustlr Glasses</Link>
                </h3>
                <p className="fz-5-single-product-price">$589.00</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 order-lg-2 order-3">
            <div className="fz-5-single-product">
              <div className="fz-5-single-product-img">
                <img src="assets/images/fz-product-36.png" alt="Image" />
                <div className="fz-5-single-product-actions">
                  <a role="button" className="fz-add-to-wishlist">
                    <i className="fa-regular fa-heart"></i>
                  </a>
                  <a role="button" className="fz-quick-view">
                    <i className="fa-regular fa-eye"></i>
                  </a>
                  <a role="button" className="fz-compare">
                    <i className="fa-regular fa-repeat"></i>
                  </a>
                  <a role="button" className="fz-add-to-cart-btn">
                    <i className="fa-regular fa-cart-shopping"></i>
                  </a>
                </div>
              </div>
              <div className="fz-5-single-product-txt">
                <div className="color-available">
                  <span className="color-1"></span>
                  <span className="color-2"></span>
                  <span className="color-3"></span>
                </div>
                <h3 className="fz-5-single-product-title">
                  <Link to="/shopDetails">Lenskart Blu Hustlr Glasses</Link>
                </h3>
                <p className="fz-5-single-product-price">$589.00</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 order-lg-3 order-4">
            <div className="fz-5-single-product">
              <div className="fz-5-single-product-img">
                <img src="assets/images/fz-product-37.png" alt="Image" />
                <div className="fz-5-single-product-actions">
                  <a role="button" className="fz-add-to-wishlist">
                    <i className="fa-regular fa-heart"></i>
                  </a>
                  <a role="button" className="fz-quick-view">
                    <i className="fa-regular fa-eye"></i>
                  </a>
                  <a role="button" className="fz-compare">
                    <i className="fa-regular fa-repeat"></i>
                  </a>
                  <a role="button" className="fz-add-to-cart-btn">
                    <i className="fa-regular fa-cart-shopping"></i>
                  </a>
                </div>
              </div>
              <div className="fz-5-single-product-txt">
                <div className="color-available">
                  <span className="color-1"></span>
                  <span className="color-2"></span>
                  <span className="color-3"></span>
                </div>
                <h3 className="fz-5-single-product-title">
                  <Link to="/shopDetails">Lenskart Blu Hustlr Glasses</Link>
                </h3>
                <p className="fz-5-single-product-price">$589.00</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 order-lg-4 order-1">
            <div className="fz-5-product-title-box">
              <h3>Sunglasses</h3>
              <p>
                Choose a Frame. Select a glasses frame, and add it to your
                shopping we will answer the phone cart.
              </p>
              <Link to="/shop-2" className="fz-5-def-btn-2">
                View Range
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SunGlassSection;
